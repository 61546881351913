import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/paratriennale/paratriennale/loveletter/src/templates/works-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextWorkFrame = makeShortcode("TextWorkFrame");
const Caption = makeShortcode("Caption");
const BpHr = makeShortcode("BpHr");
const LinkButton = makeShortcode("LinkButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`中川美枝子`}</h1>
    <p>{`視覚障害者とつくる美術鑑賞ワークショップのスタッフである全盲の中川美枝子さんは、『悪夢の続き』に対してテキストで応答しました。テキストは、ワークショップのメンバーと一緒に映像を見て、見えているもの・見えていないものについて議論したことを踏まえて執筆されました。自分のことを人前で話すことについて、どきっとさせられる応答となっています。テキストは音声読み上げでもお聞き頂けます。`}</p>
    <TextWorkFrame type="nakagawa" mdxType="TextWorkFrame" />
    <Caption before={`作品`} medium={`テキスト、2020年`} mdxType="Caption">
  《蓋の向こうからあなたへ》
    </Caption>
    <BpHr type={`dot`} mdxType="BpHr" />
    <LinkButton to={`/works/takayuki-yamamoto`} mdxType="LinkButton">作品: 山本高之</LinkButton>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      